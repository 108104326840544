<template>
  <v-main>
    <v-container
      fluid
      class="px-0 py-0 px-md-3"
    >
      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'DefaultView',
  }
</script>
<style lang="scss">
body,
html {
  overflow-x: hidden;
}
</style>
